import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
    selector: 'app-receipt-result',
    templateUrl: './receipt-result.component.html',
    styleUrls: ['./receipt-result.component.scss'],
})
export class ReceiptResultComponent implements OnInit {
    public readonly StyleType = StyleType;

    @Input()
    recipient: string;
    sendType: string;
    result: boolean;
    contact_number: string;

    value: number = 0;
    timer = 30;
    interval;

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
