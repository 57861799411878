import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BackendService} from '../../services/backend.service';
import {Product} from '../../entities/product';
import {LoyaltyPaymentAcceptComponent} from '../loyalty-payment-accept/loyalty-payment-accept.component';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
    selector: 'app-loyalty-products',
    templateUrl: './loyalty-products.component.html',
    styleUrls: ['./loyalty-products.component.scss'],
})
export class LoyaltyProductsComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    products: any;
    code: string;

    interval;
    timer = 30;

    constructor(private modalCtrl: ModalController,
                private backendService: BackendService,
                public styleService: StyleService) {
    }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer');
            }
        }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    async dismiss(status, data = undefined, queueNumber = null) {
        clearInterval(this.interval);
        if (!data) {
            data = {'status': status, 'queueNumber': queueNumber};
        }
        await this.modalCtrl.dismiss(data);
    }

    productSelected(product) {
        this.backendService.GetLoyaltyUserByLoyaltyCode(product.customer_id, this.code).subscribe(async user => {
            if (user && user.id) {
                await this.launchAcceptModal(product, user, null);
            } else {
                await this.dismiss('dismiss');
            }
        });
    }

    async launchAcceptModal(product: Product, user, additions) {
        let component: any = LoyaltyPaymentAcceptComponent;
        let cssClass: string = 'loyalty-payment-accept-modal';

        let modal = await this.modalCtrl.create({
            animated: false,
            component: component,
            cssClass: cssClass,
            componentProps: {
                'product': product,
                'user': user,
                'selectedAdditions': additions
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(modal => {
            if (modal.data != null && modal.data.status != 'dismiss') {
                this.dismiss(modal.data.status, undefined, modal.data.queueNumber);
            } else {
                this.dismiss('dismiss');
            }
        });
    }
}
