import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Product} from '../../../entities/product';
import {BackendService} from '../../../services/backend.service';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-payment-accept',
    templateUrl: './club-payment-accept.component.html',
    styleUrls: ['./club-payment-accept.component.scss'],
})
export class ClubPaymentAcceptComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;
    user: any;
    userInfo: any;
    balance: any;
    token: any;
    selectedAdditions: Product[] = [];

    timer = 30;
    interval;

    display: string[] = [];

    orderId;
    orderText = "";
    orderPrice = 0;

    clickedConfirm = false;

    constructor(
        private modalCtrl: ModalController,
        private backendService: BackendService,
        private loadingController: LoadingController,
        public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
        this.setup();
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    setup() {
        this.orderPrice = Number(this.product.price)
        this.orderText = `${this.product.name || '-'} (${this.product.price || '-'} kr.)`;
        if (this.selectedAdditions && this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderText += `, ${add.name || '-'} (${add.price || '-'} kr.)`;
                this.orderPrice += Number(add.price);
            })
        }
        this.backendService.createOrderForClub(this.product.sku, this.token).subscribe(resp => {
            this.orderId = resp.id;
            this.addOrderAdditions(this.selectedAdditions, this.orderId);
        })
    }

    clickBuyPoints() {
        let data = {
            'status': 'purchase',
            'user': this.user,
            'userInfo': this.userInfo,
            'balance': this.balance,
            'token': this.token
        }
        this.dismiss('purchase', data);
    }

    dismiss(status, data = undefined) {
        clearInterval(this.interval);
        let paymentStatus = 2;
        if (status === 'captured')
            paymentStatus = 3;

        if (!data) {
            data = {'status': status};
        }
        this.backendService.updateOrderForLoyalty(this.orderId, paymentStatus).subscribe(async () => {
            await this.modalCtrl.dismiss(data)
        })

    }

    async clickConfirm() {
        if (this.clickedConfirm)
            return;

        this.clickedConfirm = true;

        if (!this.balance.balance || this.balance.balance < this.orderPrice)
            return;

        const loading = await this.loadingController.create({
            animated: false,
            duration: 30000
        });

        await loading.present().then(() => {
            this.backendService.updateOrderForLoyalty(this.orderId, 4).subscribe(() => {
                this.backendService.runClub(this.product.sku, this.product.customer_id, this.userInfo.loyalty_code, this.orderId, this.token).subscribe(resp => {
                    loading.dismiss();
                    let data = {
                        'status': 'captured',
                        'user': this.user,
                        'userInfo': this.userInfo,
                        'balance': this.balance,
                        'token': this.token,
                        'orderId': this.orderId,
                        'queueNumber': resp.queueNumber
                    }
                    this.dismiss('captured', data);
                }, error => {
                    console.log(error);
                    loading.dismiss();
                    this.dismiss('error');
                });
            }, error => {
                console.log(error);
                loading.dismiss();
                this.dismiss('error');
            });
        });
    }

    addOrderAdditions(additions: Product[], orderId) {
        additions.forEach(addition => {
            this.backendService.addOrderAddition(addition.sku, orderId).subscribe()
        })
    }
}
