import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-resume-program',
    templateUrl: './resume-program.component.html',
    styleUrls: ['./resume-program.component.scss'],
})
export class ResumeProgramComponent implements OnInit {

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {

    }

    dismiss(status) {
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
