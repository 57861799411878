import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../../entities/product';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-payment-complete',
    templateUrl: './club-payment-complete.component.html',
    styleUrls: ['./club-payment-complete.component.scss'],
})
export class ClubPaymentCompleteComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;
    paymentType: String;
    queueNumber: Number = 0;
    userInfo: any;
    balance: any;
    selectedAdditions: Product[] = [];
    queueText: String;

    timer = 10;
    interval;

    orderText = "";

    orderPrice = 0;

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService) { }

    ngOnInit() {
        if(this.queueNumber > 0) {
            this.queueText = "Kø nummer: #" + String(this.queueNumber).slice(-2);
        }
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)

        this.orderPrice = Number(this.product.price)
        this.orderText = `${this.product.name || '-'} (${this.product.price || '-'} kr.)`;
        if (this.selectedAdditions && this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderText += `, ${add.name || '-'} (${add.price || '-'} kr.)`;
                this.orderPrice += Number(add.price);
            })
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
