import { Injectable } from '@angular/core';
import {Style} from '../entities/config';

export enum StyleType {
    BG,
    HEADLINE_BG,
    NAVBAR_BG,
    HEADLINE_TXT,
    SP_LOGO_COL,
    VS_BG,
    VS_TXT,
    CU_URL
}

@Injectable({
    providedIn: 'root'
})
export class StyleService {
    style: Style;
    constructor() { }

    getStyle(type: StyleType) {
        if (!this.style) {
            return "";
        }

        switch (type) {
            case StyleType.BG:
                return "background: " + this.style.backgroundColor + ";";
            case StyleType.HEADLINE_BG:
                return "background: " + this.style.headlineBackgroundColor + ";";
            case StyleType.HEADLINE_TXT:
                return "color: " + this.style.headlineTextColor + ";";
            case StyleType.NAVBAR_BG:
                return "background: " + this.style.navbarBackgroundColor + ";";
            case StyleType.SP_LOGO_COL:
                return "fill: " + this.style.switchpayLogoColor + ";";
            case StyleType.VS_BG:
                return "background: " + this.style.versionBoxBackgroundColor + ";";
            case StyleType.VS_TXT:
                return "color: " + this.style.versionBoxTextColor + ";";
            case StyleType.CU_URL:
                return this.style.customerLogoUrl;
        }
    }

    setStyles(style: Style) {
        this.style = style;
    }
}
