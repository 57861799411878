import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {BackendService} from '../../services/backend.service';
import {Moment} from 'moment';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
    selector: 'app-email-receipt',
    templateUrl: './email-receipt.component.html',
    styleUrls: ['./email-receipt.component.scss'],
})
export class EmailReceiptComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;
    paymentType: string;
    vendor_info: any;
    orderTimestamp: Moment;
    receiptId: number;
    selectedAdditions: Product[] = [];

    order_time: string;
    order_date: string;

    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,4}$";
    mailAddress = "";

    baseTime = 40;

    value: number = 0;
    timer = this.baseTime;
    interval;

    constructor(
        private modalCtrl: ModalController, 
        private backendService: BackendService,
        private loadingController: LoadingController,
        public styleService: StyleService
    ) { }

    ngOnInit() {
        this.setTimer()
        this.order_time = this.orderTimestamp.format('HH:mm');
        this.order_date = this.orderTimestamp.format('DD/MM-YYYY');
    }

    setTimer() {
        clearInterval(this.interval)
        this.timer = this.baseTime;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval)
                this.dismiss('timeout')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, recipient = undefined) {
        this.modalCtrl.dismiss({
            'status': status,
            'recipient': recipient
        })
    }

    async sendMail() {
        clearInterval(this.interval)

        const loading = await this.loadingController.create({
            animated: false,
            duration: 30000
        });


        await loading.present().then(() => {
            if (!this.selectedAdditions || this.selectedAdditions.length == 0) {
                this.backendService.sendEmailReceipt(this.mailAddress, this.vendor_info.point_of_sale, this.paymentType, this.order_date, this.order_time, this.product.name,
                    this.product.price.toString(), this.vendor_info.vendor, this.vendor_info.vendor_address, this.vendor_info.vendor_cvr, this.receiptId).subscribe(resp =>
                    {
                        let response = resp;
                        loading.dismiss();
                        if (response.data.error) {
                            this.dismiss('email_fail ', this.mailAddress);
                        }
                        this.dismiss('email_success', this.mailAddress);
                    }, error =>
                    {
                        loading.dismiss();
                        this.dismiss('email_fail', this.mailAddress);
                        console.log(error.error);
                    });
            } else if (this.selectedAdditions && this.selectedAdditions.length > 0) {
                let ad_prod = this.selectedAdditions[0];
                this.backendService.sendEmailReceiptAddition(this.mailAddress, this.vendor_info.point_of_sale, this.paymentType, this.order_date, this.order_time, this.product.name,
                    this.product.price.toString(), ad_prod.name, ad_prod.price.toString(), this.vendor_info.vendor, this.vendor_info.vendor_address, this.vendor_info.vendor_cvr, this.receiptId).subscribe(resp =>
                {
                    let response = resp;
                    loading.dismiss();
                    if (response.data.error) {
                        this.dismiss('email_fail ', this.mailAddress);
                    }
                    this.dismiss('email_success', this.mailAddress);
                }, error =>
                {
                    loading.dismiss();
                    this.dismiss('email_fail', this.mailAddress);
                    console.log(error.error);
                });
            }
        }, () => {
            loading.dismiss();
            this.dismiss('email_fail', this.mailAddress);
        });

        await loading.onDidDismiss().then(loadModal => {
            if (!loadModal.data) {
                this.dismiss('email_fail', this.mailAddress);
            }
        });
    }

    validMail() {
        let pattern = RegExp(this.emailPattern);
        return pattern.test(this.mailAddress);
    }

    canAt() {
        return !(this.mailAddress.indexOf('@') > 0 || this.mailAddress.length == 0)
    }

    keypadClick(val) {
        this.setTimer()
        this.mailAddress = this.mailAddress + val;
    }

    keypadDelete() {
        this.setTimer()
        if (this.mailAddress.length > 0) {
            this.mailAddress = this.mailAddress.substr(0, this.mailAddress.length - 1)
        }
    }

    displayString() {
        if (this.mailAddress.length < 22) {
            return this.mailAddress;
        }
        else {
            return "..." + this.mailAddress.substr(this.mailAddress.length - 21)
        }
    }
}
