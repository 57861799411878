import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {BackendService} from '../../services/backend.service';
import {version} from '../../../environments/version';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-products-selector',
  templateUrl: './products-selector.component.html',
  styleUrls: ['./products-selector.component.scss'],
})
export class ProductSelectorComponent implements OnInit, OnDestroy {
  public readonly StyleType = StyleType;

  @Input()
  products: Product[];
  title: String;

  code: number;

  interval;
  timer = 30;
  version: string;

  constructor(
      private modalCtrl: ModalController,
      private backendService: BackendService,
      public styleService: StyleService) {
    this.version = version;
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer')
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  dismiss(status) {
    this.modalCtrl.dismiss({
      'status': status,
    })
  }

  productSelected(product) {
    if (!product.enabled) {
      if (product.resumable) {
        this.backendService.sendMeasurementResume(product.sku).subscribe(() => {
          product.resumable = false;
          product.disabled_state = "Afvent...";
        })
      } else {
      this.dismiss('dismiss')
      }
    } else {
      this.modalCtrl.dismiss({
        'status': 'next',
        'product': product
      });
    }
  }

}
